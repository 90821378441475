import type { AxiosResponse } from 'axios'
import { http } from '@/services/http.service'
import type { IRegisterUser, IUserLogin, IResendEmail } from '@/types/general'

export const login = (user: IUserLogin): Promise<AxiosResponse> => {
  return http.post('users/sign_in', { user })
}

export const logout = (): Promise<AxiosResponse> => {
  return http.delete('users/sign_out')
}

export const register = (user: IRegisterUser): Promise<AxiosResponse> => {
  return http.post('users', { user })
}
export const resendConfirmation = (user: IResendEmail): Promise<AxiosResponse> => {
  return http.post('users/confirmation', { user })
}
export const forgetPassword = (user: IResendEmail): Promise<AxiosResponse> => {
  return http.post('users/password', { user })
}
