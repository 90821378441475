import { http, setHTTPHeader } from './http.service'
import type { IUserLogin, IRegisterUser, IResendEmail } from '@/types/general'
import { login, logout, register, resendConfirmation, forgetPassword } from '@/apis/auth.api'

class AuthService {
  async login(user: IUserLogin) {
    return login(user).then((response) => {
      const token = response.data.token
      localStorage.setItem('user', JSON.stringify(response.data.user))
      localStorage.setItem('token', token)
      setHTTPHeader({ Authorization: token })
      return response.data.user
    })
  }

  async logout() {
    return logout().finally(() => {
      delete http.defaults.headers.common['Authorization']
      this.clearCache()
      this.returnMain()
    })
  }

  async register(user: IRegisterUser) {
    return register(user)
  }
  
  resendConfirmation(user: IResendEmail) {
    return resendConfirmation(user)
  }
  forgetPassword(user: IResendEmail) {
    return forgetPassword(user)
  } 

  clearCache(): void {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    document.cookie = '_certificate_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
  returnMain() {
    location.href = '/'
  }

  getUser() {
    const user = localStorage.getItem('user')
    if (user) {
      try {
        return JSON.parse(user)
      } catch {
        return null
      }
    }

    return null
  }

  getToken() {
    return localStorage.getItem('token')
  }
}

const instance = new AuthService()

export default instance
